import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useMainIngestMutation, useMainPatchMutation, useMainRetrieveQuery } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as Yup from "yup"
import { toast } from "react-toastify"
import NumberField from "components/shared/NumberField"
import Modal from "react-responsive-modal"
import Matrix from "components/Matrix"
import LatLngPicker from "components/back/LatLngPicker"
import _ from "lodash"

function Communities() {
	const q = useMainRetrieveQuery({ action: "getCommunities", tags: ["communities"] })
	const options = useMainRetrieveQuery({ action: "getOptions", tags: ["options"] })
	const developers = useMainRetrieveQuery({ action: "getDevelopers", tags: ["developers"] })
	const submarkets = useMainRetrieveQuery({ action: "getSubmarkets", tags: ["submarkets"] })
	const builders = useMainRetrieveQuery({ action: "getBuilders", tags: ["builders"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useMainPatchMutation()
	const [communities, setCommunities] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [markerWipe, triggerMarkerWipe] = useState(false)
	let model = {
		name: "",
		url: "",
		developer: "",
		builders: [],
		city: "",
		comments: ""
	}
	useEffect(() => {
		if (q.data) {
			setCommunities(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
		})
	}

	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "developer",
			header: "Developer",
			accessorFn: (row) => helpers.translateID("developer", row, developers),
		},
		{
			id: "city",
			header: "City",
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if (props?.new) {
						resp = await create({ ...values, action: "createCommunity", tags: ["communities"] })
					} else {
						resp = await patch({
							...values,
							type: "communities",
							action: "updateResource",
							tags: ["communities"],
						})
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f.values) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "submarket")}>
									<ErrorMessage name="submarket" component={FormError} className="text-red-500" />
									<label>Submarket</label>
									<Select
										name="submarket"
										value={f.values.submarket}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(submarkets)}
										onBlur={f.setFieldTouched}
										isMulti
									/>
								</li>
								<li className={helpers.formField(f, "developer")}>
									<ErrorMessage name="developer" component={FormError} className="text-red-500" />
									<label>Developer</label>
									<Select
										name="developer"
										value={f.values.developer}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(developers)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "url")}>
									<ErrorMessage name="url" component={FormError} />
									<label>URL</label>
									<Field type="text" name="url" />
								</li>
								<li className={helpers.formField(f, "city")}>
									<ErrorMessage name="city" component={FormError} />
									<label>City</label>
									<Field type="text" name="city" />
								</li>
								<li className={helpers.formField(f, "amenities")}>
									<ErrorMessage name="amenities" component={FormError} className="text-red-500" />
									<label>Amenities</label>
									<Select
										name="amenities"
										value={f.values.amenities}
										onChange={f.setFieldValue}
										options={helpers.buildDbOptions(options, "amenities")}
										onBlur={f.setFieldTouched}
										isMulti
									/>
								</li>
								<li className={helpers.formField(f, "comments")}>
									<ErrorMessage name="comments" component={FormError} className="text-red-500" />
									<label>Comments</label>
									<Field component="textarea" name="comments" />
								</li>
								<li className={helpers.formField(f, "hoa")}>
									<ErrorMessage name="hoa" component={FormError} />
									<label>HOA</label>
									<NumberField
										name="hoa"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.hoa}
										error={f.errors.hoa}
										value={f.values.hoa}
										decimals={2}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "lat")}>
									<ErrorMessage name="lat" component={FormError} />
									<label>Latitude</label>
									<NumberField
										name="lat"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lat}
										error={f.errors.lat}
										value={f.values.lat}
										thousandSeparator={true}
										decimals={8}
										float={true}
									/>
								</li>
								<li className={helpers.formField(f, "lng")}>
									<ErrorMessage name="lng" component={FormError} />
									<label>Longitude</label>
									<NumberField
										name="lng"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.lng}
										error={f.errors.lng}
										value={f.values.lng}
										thousandSeparator={true}
										decimals={8}
										float={true}
									/>
								</li>
							</ul>
							<h3>Builders</h3>
							<Matrix
								delete={true}
								fields={[
									{
										id: "builder",
										name: "Builder",
										type: "select",
										options: helpers.buildOpts(builders),
									},
									{
										id: "active",
										name: "Active",
										type: "select",
										options: [
											{ value: 1, label: "Active" },
											{ value: 0, label: "Inactive" },
										],
									},
								]}
								data={f.values.builders}
								onChange={(v) => f.setFieldValue("builders", v)}
							/>
							<h3 className="mt-24">Select Center Point</h3>
							<LatLngPicker height={400} formik={f} {...props} triggerWipe={markerWipe} />
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add Community</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Communities</h2>
			<FullTable data={communities} columns={columns} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Community</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Communities
