import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import moment from "moment"
import _ from "lodash"
import numeral from "numeral"
import helpers from "helpers"


async function ExcelExportLocalTable(props) {
	let set = _.cloneDeep(props.data)
	let f = []
	// Remove react-table crud
	let translateTable = (d) => {
		let newTable = []
		_.forEach(props.data, (row) => {
			let newRow = {}
			_.forEach(row, (v, k) => {
				if(props.unset?.includes(k)) return
				// Change the key names
				k = k.replace(/_/g, " ")
				k = k.replace(/\b\w/g, (char) => char.toUpperCase())
				// Change the values
				if (v && typeof v === "object" && Array.isArray(v)) {
					if (!v[0]) v = ""
					else if (typeof v[0] === "string" || typeof v[0] === "number") {
						v = v.join(", ")
					} else if (_.isPlainObject(v[0]) && v[0]?.name) {
						v = _.map(v, "name").join(", ")
					}
				} else if (v && typeof v === "object" && v._owner) {
					//? React element
					v = v.props.children
				} else if (v && typeof v === "object" && _.isPlainObject(v)) {
					let str = ""
					_.forEach(v, (val, key) => {
						str += `${_.capitalize(key)}: ${val}, `
					})
					str = _.trim(str, ", ")
					v = str
				}

				newRow[k] = v
			})
			newTable.push(newRow)
		})
		return newTable
	}
	_.forEach(set, (v) => {
		let row = v
		// Remove columns from props.unset
		_.forEach(row, (r, k) => {
			if (typeof r === "number" && k.toLowerCase().includes("date")) {
				row[k] = moment.unix(r).format("MM/DD/YY")
			} else if (typeof r === "number" && r > 946706400) {
				//! The number above is for 1/1/2000.  Numbers shouldn't ever get that high for prices and the like, so it's probably a timestamp.  Leaving a note in case this comes up.
				row[k] = moment.unix(r).format("MM/DD/YY")
			} else if (k.toLowerCase().includes("percent")) {
				row[k] = numeral(r / 100).format("0.00%")
			} else if (r && typeof r !== "string" && !isNaN(r) && Number.isInteger(r)) {
				//row[k] = numeral(r).format('0,0')
			}
		})
		f.push(row)
	})
	f = helpers.translateArray(f, props.payload) // this translates things like district IDs to comma-separated district names
	f = translateTable(f)
	//const { default: XLSX } = await import("xlsx")
	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
	const fileExtension = ".xlsx"
	const ws = XLSX.utils.json_to_sheet(f)
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
	const d = new Blob([excelBuffer], { type: fileType })
	FileSaver.saveAs(d, props.title + fileExtension)
}

export default ExcelExportLocalTable
