import { useState } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"

function Nav() {
	const [open, setOpen] = useState(null)
	const BuildNav = () => {
		const structure = [
			/* {
				title: "Dashboard",
				icon: "fa-regular fa-hexagon-image",
				link: "/admin",
			}, */
			{
				title: "Admin",
				icon: "fa-duotone fa-screwdriver-wrench",
				link: "/admin",
				children: [
					{
						title: "Builders",
						link: "/admin/builders",
					},
					{
						title: "Communities",
						link: "/admin/communities",
					},
					{
						title: "Floorplans",
						link: "/admin/floorplans",
					},
					{
						title: "Markets",
						link: "/admin/markets",
					},
					{
						title: "Submarkets",
						link: "/admin/submarkets",
					},
					{
						title: "Subdivisions",
						link: "/admin/subdivisions",
					},
					{
						title: "Neighborhoods",
						link: "/admin/neighborhoods",
					},
					{
						title: "Sections",
						link: "/admin/sections",
					},
					{
						title: "MUDs",
						link: "/admin/muds",
					},
					{
						title: "Schools",
						link: "/admin/schools",
					},
					{
						title: "Developers",
						link: "/admin/developers",
					},
				],
			},
			{
				title: "Users",
				icon: "fa-solid fa-users-rectangle",
				link: "/admin/users",
			},
			{
				title: "Entry",
				icon: "fa-solid fa-pen-field",
				link: "/admin/entry",
			},
		]
		let ret = structure.map((item, index) => {
			let children = null
			if (item.children && open === index) {
				children = _.map(item.children, (child, index) => {
					return (
						<div
							key={index}
							className="my-1 cursor-pointer transition duration-300 flex items-center text-slate-500 hover:bg-blue-100 rounded-lg child:hover:text-slate-500 child:transition child:duration-300">
							<Link to={child.link} className="w-full h-full block py-1 px-8">
								<span className="ml-4 text-sm">{child.title}</span>
							</Link>
						</div>
					)
				})
			}
			let parentComponent = (
				<Link
					to={item.link}
					className="w-full h-full block py-2 child:hover:text-slate-500 transition duration-300 text-slate-500 hover:bg-blue-100 rounded-lg">
					<i className={`${item.icon} text-xl ml-3`}></i>
					<span className="ml-4 font-semibold text-sm">{item.title}</span>
				</Link>
			)
			if (item.children) {
				parentComponent = (
					<div className="w-full h-full block py-2 child:hover:text-slate-500 transition duration-300 text-slate-500 hover:bg-blue-100 rounded-lg">
						<i className={`${item.icon} text-xl ml-3`}></i>
						<span className="ml-4 font-semibold text-sm">{item.title}</span>
					</div>
				)
			}
			return (
				<div key={index} className="my-1 cursor-pointer transition duration-300 flex flex-col justify-center">
					<div
						onClick={() => {
							if (open === index) {
								setOpen(null)
							} else {
								setOpen(index)
							}
						}}>
						{parentComponent}
					</div>
					{children}
				</div>
			)
		})
		return ret
	}
	return (
		<div className="">
			<BuildNav />
		</div>
	)
}

export default Nav
