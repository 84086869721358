import React from "react"
import { NumericFormat } from "react-number-format"

function NumberField(props) {
	const handleChange = (v) => {
		if (props.numeric) {
			v.value = parseInt(v.value)
		} else if (props.float) {
			v.value = parseFloat(v.value)
		}
		if (props.row) {
			props.onChange(`rows[${props.rowIndex}][${props.name}]`, v.value)
		} else {
			props.onChange(props.name, v.value)
		}
	}

	const clear = () => {
		props.onChange(props.name, null)
	}

	let decimals = 2
	if (props.coord) decimals = 6
	if (props.decimals) decimals = props.decimals
	return (
		<>
			<NumericFormat
				thousandSeparator={props.thousandSeparator}
				prefix={props.prefix}
				//onValueChange={(v) => { props.onChange(props.name, v.value) }}
				onValueChange={handleChange}
				onBlur={() => {
					props.onBlur(props.name, true)
				}}
				className="form__input"
				format={props.format}
				mask={props.mask}
				value={props.value ?? ""} // passing null to this component does not clear the field, only "" does
				decimalScale={decimals}
				fixedDecimalScale={props.float ? true : false}
			/>
			{props.clearable ? (
				<div className="numberInput__close" onClick={() => clear()}>
					X
				</div>
			) : null}
		</>
	)
}

export default NumberField
