import React, { useEffect, useState } from "react"
import { useGetPayloadQuery } from "services/api"
import { useNavigate } from "react-router-dom"
import { jwtDecode } from "jwt-decode"
import { Route, Link, Routes } from "react-router-dom"
import Nav from "components/back/Nav"
import Users from "components/back/Users"
import Entry from "components/back/Entry"
import Builders from "components/back/Builders"
import helpers from "helpers"
import "react-responsive-modal/styles.css"
import Communities from "components/back/Communities"
import Markets from "components/back/Markets"
import Submarkets from "components/back/Submarkets"
import Subdivisions from "components/back/Subdivisions"
import Neighborhoods from "components/back/Neighborhoods"
import Sections from "components/back/Sections"
import Muds from "components/back/Muds"
import Schools from "components/back/Schools"
import Developers from "components/back/Developers"
import Floorplans from "components/back/Floorplans"

function Admin() {
	const navigate = useNavigate()

	useEffect(() => {
		// Apply `admin` class to body
		document.body.classList.add("admin")
	})

	// Check for appropriate permission
	useEffect(() => {
		let user = helpers.currentUser()
		if (!user) {
			navigate("/login")
		}
		if (user.aud !== 1) {
			navigate("/login")
		}
	})

	const logout = () => {
		localStorage.removeItem("user")
		navigate("/login")
	}

	const { data, error, isLoading } = useGetPayloadQuery()
	if (error) return <div className="text-center pt-24">System down for maintenance. Please try again shortly.</div>
	if (isLoading) return <div className="App">Loading</div>

	return (
		<div className="admin">
			<div className="border-b border-slate-200 py-4">
				<div className="flex container justify-between">
					<div className="w-1/12">
						<img
							src="https://cbas-primary.s3.us-east-2.amazonaws.com/logo-color.svg"
							className=""
							alt="Logo"
						/>
					</div>
					<div className="w-4/12 text-right flex items-center justify-end">
						<div
							className="text-white bg-indigo-500 px-10 py-2 cursor-pointer inline-block hover:bg-indigo-600 transition duration-300 rounded-md mr-4"
							onClick={() => {
								navigate("/")
							}}>
							To App
						</div>
						<div
							className="text-white bg-indigo-500 px-10 py-2 cursor-pointer inline-block hover:bg-indigo-600 transition duration-300 rounded-md"
							onClick={logout}>
							Logout
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="flex">
					<div className="w-2/12 pt-8">
						<Nav />
					</div>
					<div className="w-10/12 pl-3 pt-8">
						{/* Routes are relative to /admin */}
						<Routes>
							<Route path="/users" element={<Users />} />
							<Route path="/entry" element={<Entry />} />
							<Route path="/builders" element={<Builders />} />
							<Route path="/communities" element={<Communities />} />
							<Route path="/markets" element={<Markets />} />
							<Route path="/submarkets" element={<Submarkets />} />
							<Route path="/floorplans" element={<Floorplans />} />
							<Route path="/subdivisions" element={<Subdivisions />} />
							<Route path="/neighborhoods" element={<Neighborhoods />} />
							<Route path="/sections" element={<Sections />} />
							<Route path="/muds" element={<Muds />} />
							<Route path="/schools" element={<Schools />} />
							<Route path="/developers" element={<Developers />} />
						</Routes>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Admin
