import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useMainIngestMutation, useMainPatchMutation, useMainRetrieveQuery } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import _ from "lodash"

function Users() {
	const q = useMainRetrieveQuery({ action: "getUsers", tags: ["users"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useMainPatchMutation()
	const [users, setUsers] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	let model = {
		first: "",
		last: "",
		email: "",
		company: "",
		status: null,
		group: null,
	}
	useEffect(() => {
		if (q.data) {
			setUsers(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			first: Yup.string().required("Required"),
			last: Yup.string().required("Required"),
			email: Yup.string().email("Invalid email address").required("Required"),
		})
	}

	const columns = [
		{
			id: "first",
			header: "First Name",
		},
		{
			id: "last",
			header: "Last Name",
		},
		{
			id: "email",
			header: "Email",
		},
		{
			id: "company",
			header: "Company",
		},
		{
			id: "status",
			header: "Status",
			filterFn: "formatted",
			accessorFn: (row) => {
				return row.status === 1 ? "Active" : "Inactive"
			},
		},
		{
			id: "group",
			header: "Group",
			accessorFn: (row) => {
				return row.group === 1 ? "Admin" : "Client"
			},
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	let groupOptions = [
		{ value: 1, label: "Admin" },
		{ value: 50, label: "Client" },
	]

	let statusOptions = [
		{ value: 1, label: "Active" },
		{ value: 0, label: "Inactive" },
	]

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if(props?.new) {
						resp = await create({ ...values, action: "createUser", tags: ["users"] })
					}
					else {
						resp = await patch({ ...values, type: 'users', action: "updateResource", tags: ["users"] })
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f.values) */}
							<ul className="formList">
								<li className={helpers.formField(f, "first")}>
									<ErrorMessage name="first" component={FormError} />
									<label>First Name</label>
									<Field type="text" name="first" />
								</li>
								<li className={helpers.formField(f, "last")}>
									<ErrorMessage name="last" component={FormError} className="text-red-500" />
									<label>Last Name</label>
									<Field type="text" name="last" />
								</li>
								<li className={helpers.formField(f, "email")}>
									<ErrorMessage name="email" component={FormError} className="text-red-500" />
									<label>Email</label>
									<Field type="email" name="email" />
								</li>
								<li className={helpers.formField(f, "company")}>
									<ErrorMessage name="company" component={FormError} className="text-red-500" />
									<label>Company</label>
									<Field type="text" name="company" />
								</li>
								<li className={helpers.formField(f, "status")}>
									<ErrorMessage name="status" component={FormError} className="text-red-500" />
									<label>Status</label>
									<Select
										name="status"
										value={f.values.status}
										onChange={f.setFieldValue}
										options={statusOptions}
									/>
								</li>
								<li className={helpers.formField(f, "group")}>
									<ErrorMessage name="group" component={FormError} className="text-red-500" />
									<label>Group</label>
									<Select
										name="group"
										value={f.values.group}
										onChange={f.setFieldValue}
										options={groupOptions}
										onBlur={f.setFieldTouched}
									/>
								</li>
							</ul>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add User</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Users</h2>
			<FullTable data={users} columns={columns} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit User</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Users
