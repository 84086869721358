import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useMainIngestMutation, useMainPatchMutation, useMainRetrieveQuery } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import _ from "lodash"

function Schools() {
	const s = useMainRetrieveQuery({ action: "getSchools", tags: ["schools"] })
	const d = useMainRetrieveQuery({ action: "getSchoolDistricts", tags: ["school_districts"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useMainPatchMutation()
	const [schools, setSchools] = useState([])
	const [districts, setDistricts] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [editModalDistrict, setEditModalDistrict] = useState(false)
	let model = {
		name: "",
		school_type: "",
		district: "",
	}
	useEffect(() => {
		if (s.data) {
			setSchools(buildRows(s.data))
		}
	}, [s])

	useEffect(() => {
		if (d.data) {
			setDistricts(buildRows(d.data, "districts"))
		}
	}, [d])

	const buildRows = (data, type) => {
		let ret = []
		_.forEach(data, (item) => {
			let edit = null
			if (type === "districts") {
				edit = (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModalDistrict(true)
						}}></i>
				)
			} else {
				edit = (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				)
			}
			ret.push({
				...item,
				edit,
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
		})
	}

	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "school_type",
			header: "School Type",
			accessorFn: (row) => {
				if (row.school_type === 1) return "Elementary"
				if (row.school_type === 2) return "Middle"
				if (row.school_type === 3) return "High"
				if(row.school_type === 4) return "Other"
			},
		},
		{
			id: "school_district",
			header: "School District",
			accessorFn: (row) => helpers.translateID("district", row, d),
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const columnsDistrict = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if (props?.new) {
						resp = await create({ ...values, action: "createSchool", tags: ["schools"] })
					} else {
						resp = await patch({
							...values,
							type: "schools",
							action: "updateResource",
							tags: ["schools"],
						})
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "school_type")}>
									<ErrorMessage name="metro" component={FormError} className="text-red-500" />
									<label>School Type</label>
									<Select
										name="school_type"
										value={f.values.school_type}
										onChange={f.setFieldValue}
										options={[
											{ value: 1, label: "Elementary" },
											{ value: 2, label: "Middle" },
											{ value: 3, label: "High" },
										]}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "district")}>
									<ErrorMessage name="metro" component={FormError} className="text-red-500" />
									<label>School District</label>
									<Select
										name="district"
										value={f.values.district}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(d)}
										onBlur={f.setFieldTouched}
									/>
								</li>
							</ul>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	const DisplayFormDistrict = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if (props?.new) {
						resp = await create({ ...values, action: "createSchoolDistrict", tags: ["school_districts"] })
					} else {
						resp = await patch({
							...values,
							type: "school_districts",
							action: "updateResource",
							tags: ["school_districts"],
						})
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
						setEditModalDistrict(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f.values) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
							</ul>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add School</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Schools</h2>
			<FullTable data={schools} columns={columns} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit School</h2>
				<DisplayForm />
			</Modal>
			<div className="h-1 mt-36 border-t-2 border-primary"></div>
			<div className="group mt-24">
				<h2>Add School District</h2>
				<DisplayFormDistrict new={true} />
			</div>
			<h2 className="mt-12">Current Schools Districts</h2>
			<FullTable data={districts} columns={columnsDistrict} />
			<Modal open={editModalDistrict} onClose={() => setEditModalDistrict(false)} center>
				<h2>Edit School District</h2>
				<DisplayFormDistrict />
			</Modal>
		</div>
	)
}

export default Schools
