import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useCreateEntriesMutation, useMainRetrieveQuery, useModifyEntryMutation } from "services/api"
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import NumberField from "components/shared/NumberField"
import _ from "lodash"
import moment from "moment"
import classNames from "classnames"

function Entry() {
	const q = useMainRetrieveQuery({ action: "getEntries", tags: ["entries"] })
	const sections = useMainRetrieveQuery({ action: "getSections", tags: ["sections"] })
	const communities = useMainRetrieveQuery({ action: "getCommunities", tags: ["communities"] })
	const builders = useMainRetrieveQuery({ action: "getBuilders", tags: ["builders"] })
	const subdivisions = useMainRetrieveQuery({ action: "getSubdivisions", tags: ["subdivisions"] })
	const [create, createData] = useCreateEntriesMutation({
		refetchQueries: ["MainRetrieve"],
	})
	const [modify, createModify] = useModifyEntryMutation()
	const [entries, setEntries] = useState([])
	const [editData, setEditData] = useState(null)
	const [subdivision, setSubdivision] = useState(null)
	const [quarter, setQuarter] = useState(moment().startOf("quarter").unix())
	const [editModal, setEditModal] = useState(false)
	let model = {
		section: "",
		starts: "",
		closings: "",
		occupied: "",
		models: "",
		complete_vacant: "",
		under_construction: "",
		vdl: "",
		futures: "",
		total: "",
		builder: "",
		lot_type: "",
		num_lots: "",
		subdivision: "",
		quarter: moment().startOf("quarter").unix(),
	}

	useEffect(() => {
		//console.log("Updating Entries...")
		if (q.data) {
			//console.log(q)
			setEntries(buildRows(q.data))
		}
	}, [sections.isSuccess, communities.isSuccess, builders.isSuccess, subdivisions.isSuccess, q.status])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return yup.object().shape({
			section: yup.number().required("Required"),
			quarter: yup.number().required("Required"),
		})
	}

	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "quarter",
			header: "Quarter",
			accessorFn: (row) => {
				if (row.quarter) return `Q${moment.unix(row.quarter).format("Q YYYY")}`
				return ""
			},
		},
		{
			id: "sectionName",
			header: "Section",
			//accessorFn: (row) => helpers.translateID("section", row, sections),
		},
		{
			id: "lot_type",
			header: "Lot Type",
		},
		{
			id: "builder",
			header: "Builder",
			accessorFn: (row) => helpers.translateID("builder", row, builders),
		},
		{
			id: "subdivision",
			header: "Subdivision",
			accessorFn: (row) => helpers.translateID("subdivision", row, subdivisions),
		},
		{
			id: "community",
			header: "Community",
			accessorFn: (row) => helpers.translateID("community", row, communities),
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const buildBuilders = (f) => {
		// This weeds out builders not in the section they chose
		if (!f.values.section) return []
		let store = []
		_.forEach(sections?.data, (v) => {
			_.forEach(v.lot_types_builders, (r) => {
				store.push(r.builder)
			})
		})
		let filtered = _.filter(builders?.data, (r) => store.includes(r.id))
		let ret = []
		_.forEach(filtered, (v) => {
			ret.push({ value: v.id, label: v.name })
		})

		return ret
	}

	const buildSubdivisions = () => {
		let ret = []
		if (!subdivisions?.data) return []
		_.forEach(subdivisions.data, (item) => {
			let label = item.name
			if (item.communityName) label += ` - ${item.communityName}`
			if (item.developerName) label += ` - ${item.developerName}`
			if (item.submarketName) label += ` - ${item.submarketName}`
			if (item.city) label += ` - ${item.city}`

			ret.push({ value: item.id, label })
		})
		return ret
	}

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					const body = new FormData()
					_.forEach(values, (v, k) => {
						if (v === "null") return
						body.append(k, v)
					})
					body.append("type", "entries")
					body.append("action", "createEntries")
					body.append("tags", ["entries"])
					let resp = await modify(body)
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f) */}
							<ul className="formList">
								<li className={helpers.formField(f, "quarter")}>
									<ErrorMessage name="quarter" component={FormError} className="text-red-500" />
									<label>Quarter</label>
									<Select
										name="quarter"
										value={f.values.quarter}
										onChange={f.setFieldValue}
										options={helpers.buildQuarters()}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "section")}>
									<ErrorMessage name="section" component={FormError} className="text-red-500" />
									<label>Section</label>
									<Select
										name="section"
										value={f.values.section}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(sections, true)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "builder")}>
									<ErrorMessage name="builder" component={FormError} className="text-red-500" />
									<label>Builder</label>
									<Select
										name="builder"
										value={f.values.builder}
										onChange={f.setFieldValue}
										options={buildBuilders(f)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "sqft_min")}>
									<ErrorMessage name="sqft_min" component={FormError} />
									<label>Sqft Min</label>
									<NumberField
										name="sqft_min"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.sqft_min}
										error={f.errors.sqft_min}
										value={f.values.sqft_min}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "sqft_max")}>
									<ErrorMessage name="sqft_max" component={FormError} />
									<label>Sqft Max</label>
									<NumberField
										name="sqft_max"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.sqft_max}
										error={f.errors.sqft_max}
										value={f.values.sqft_max}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "starts")}>
									<ErrorMessage name="starts" component={FormError} />
									<label>Starts</label>
									<NumberField
										name="starts"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.starts}
										error={f.errors.starts}
										value={f.values.starts}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "closings")}>
									<ErrorMessage name="closings" component={FormError} />
									<label>Closings</label>
									<NumberField
										name="closings"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.closings}
										error={f.errors.closings}
										value={f.values.closings}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "occupied")}>
									<ErrorMessage name="occupied" component={FormError} />
									<label>Occupied</label>
									<NumberField
										name="occupied"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.occupied}
										error={f.errors.occupied}
										value={f.values.occupied}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "models")}>
									<ErrorMessage name="models" component={FormError} />
									<label>Models</label>
									<NumberField
										name="models"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.models}
										error={f.errors.models}
										value={f.values.models}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "complete_vacant")}>
									<ErrorMessage name="complete_vacant" component={FormError} />
									<label>Complete Vacant</label>
									<NumberField
										name="complete_vacant"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.complete_vacant}
										error={f.errors.complete_vacant}
										value={f.values.complete_vacant}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "under_construction")}>
									<ErrorMessage name="under_construction" component={FormError} />
									<label>Under Construction</label>
									<NumberField
										name="under_construction"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.under_construction}
										error={f.errors.under_construction}
										value={f.values.under_construction}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "vdl")}>
									<ErrorMessage name="vdl" component={FormError} />
									<label>VDL</label>
									<NumberField
										name="vdl"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.vdl}
										error={f.errors.vdl}
										value={f.values.vdl}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "futures")}>
									<ErrorMessage name="futures" component={FormError} />
									<label>Futures</label>
									<NumberField
										name="futures"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.futures}
										error={f.errors.futures}
										value={f.values.futures}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "total")}>
									<ErrorMessage name="total" component={FormError} />
									<label>Total</label>
									<NumberField
										name="total"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.total}
										error={f.errors.total}
										value={f.values.total}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "observed_status")}>
									<ErrorMessage
										name="observed_status"
										component={FormError}
										className="text-red-500"
									/>
									<label>Observed Status</label>
									<Select
										name="observed_status"
										value={f.values.observed_status}
										onChange={f.setFieldValue}
										options={helpers.observedStatuses()}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "pdf")}>
									<ErrorMessage name="pdf" component={FormError} className="text-red-500" />
									<label>
										PDF{" "}
										{f.values.pdf ? (
											<a
												href={`https://cbas-primary.s3.us-east-2.amazonaws.com${f.values.pdf}`}
												target="_blank"
												className="text-xs text-darkBlue pl-4 font-bold">
												Current Scan
											</a>
										) : null}
									</label>
									<input
										name="pdf"
										type="file"
										className="form__input"
										onChange={(event) => {
											f.setFieldValue("pdf", event.currentTarget.files[0])
										}}
									/>
								</li>
							</ul>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	const BuildTable = (f) => {
		if (!subdivision || !sections) return null
		let init = []
		_.forEach(sections?.data, (v) => {
			if (v.subdivision !== subdivision) return
			_.forEach(v.lot_types_builders, (r) => {
				let builder = _.find(builders?.data, { id: r.builder })
				let section = _.find(sections?.data, { id: v.id })
				init.push({
					section: v.id,
					sectionName: section?.name || "",
					starts: "",
					closings: "",
					occupied: "",
					models: "",
					complete_vacant: "",
					under_construction: "",
					vdl: "",
					futures: "",
					builder: r.builder,
					builderName: builder?.name || "",
					lot_type: r.lot_type,
					num_lots: r.num_lots,
					subdivision: v.subdivision,
					observed_status: "",
					quarter: moment().startOf("quarter").unix(),
				})
			})
			//? If no lot types builders, that means we just have a section in that we're assuming is a future
			if (v.lot_types_builders.length === 0) {
				init.push({
					section: v.id,
					sectionName: v.name,
					starts: "",
					closings: "",
					occupied: "",
					models: "",
					complete_vacant: "",
					under_construction: "",
					vdl: "",
					futures: "",
					builder: "",
					builderName: "",
					lot_type: "",
					num_lots: "",
					subdivision: v.subdivision,
					observed_status: "",
					quarter: moment().startOf("quarter").unix(),
					isFuture: true,
				})
			}
		})

		const blurCheck = (f) => {
			// When a number is blurred, this check runs to see if the fields equal out to the total
			_.forEach(f.values, (v, k) => {
				let orig = _.cloneDeep(v)
				let fields = [
					"closings",
					"complete_vacant",
					"futures",
					"models",
					"occupied",
					"starts",
					"under_construction",
					"vdl",
				]
				// Translate "" to 0's
				_.forEach(fields, (r) => {
					if (!orig[r]) orig[r] = 0
				})
				// Calculate total
				let total =
					orig.closings +
					orig.complete_vacant +
					orig.futures +
					orig.models +
					orig.occupied +
					orig.starts +
					orig.under_construction +
					orig.vdl
				orig.tracker = total - orig.num_lots
				if (k === 0) {
					/* console.log(orig)
					console.log(v) */
				}
				v.tracker = orig.tracker
				f.setFieldValue(k, v)
				//arrayHelpers.replace(k, v)
			})
		}

		const Tracker = (props) => {
			let f = props.f
			let total = f.values[props.k].tracker
			return <span className={total === 0 ? "text-green-500" : "text-red-500"}>{total}</span>
		}

		const Rows = (props) => {
			let f = props.f
			let arrayHelpers = props.helpers
			let rows = []
			let set = _.cloneDeep(init)
			_.forEach(set, (v, k) => {
				if (v.subdivision !== subdivision) return
				rows.push(
					<tr className="child:text-xs child:text-left" key={`section-${v.section}-${v.builder}`}>
						<td>{v.sectionName}</td>
						<td>{v.builderName}</td>
						<td>{v.lot_type}</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.starts`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.closings`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.occupied`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.models`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.complete_vacant`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.under_construction`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.vdl`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							{!v.isFuture && (
								<NumberField
									name={`${k}.futures`}
									type="text"
									className="form__input"
									onChange={f.setFieldValue}
									numeric={v.float ? false : true}
									onBlur={() => blurCheck(f, arrayHelpers)}
									float={v.float || null}
									decimals={v.decimals || null}
								/>
							)}
						</td>
						<td>
							<Select
								name={`${k}.observed_status`}
								value={f.values.observed_status}
								onChange={f.setFieldValue}
								options={helpers.observedStatuses()}
								onBlur={f.setFieldTouched}
							/>
						</td>
						<td>
							{!v.isFuture && (
								<input
									name={`${k}.pdf`}
									type="file"
									onChange={(event) => {
										f.setFieldValue(`${k}.pdf`, event.currentTarget.files[0])
									}}
								/>
							)}
						</td>
						<td>
							{v.num_lots} / <Tracker f={f} k={k} />
						</td>
					</tr>
				)
			})
			return rows
		}

		const validation = () => {
			return yup.array().of(
				yup.object().shape({
					closings: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					occupied: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					models: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					complete_vacant: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					under_construction: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					vdl: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					futures: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
					observed_status: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.required("Required") : schema.notRequired()
					}),
					starts: yup.number().when("isFuture", (isFuture, schema) => {
						return isFuture ? schema.notRequired() : schema.required("Required")
					}),
				})
			)
		}

		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				validateOnMount={true}
				onSubmit={async (values, { resetForm, setSubmitting, isValid }) => {
					const body = new FormData()
					_.forEach(values, (v, k) => {
						_.forEach(v, (r, i) => {
							body.append(`${k}[${i}]`, r)
						})
					})
					body.append("type", "entries")
					body.append("action", "createEntries")
					body.append("tags", ["entries"])
					let resp = await create(body)
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
						setSubdivision(null)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<Form>
						{console.log(f)}
						<FieldArray
							name="sections"
							render={(arrayHelpers) => (
								<>
									<table className="EntryTable w-full">
										<thead>
											<tr className="child:text-[11px] child:text-left child:bg-neutral-200 child:py-2 child:xl:px-2">
												<th>Section</th>
												<th className="w-[10%]">Builder</th>
												<th>Lot Type</th>
												<th>Starts</th>
												<th>Closings</th>
												<th>Occupied</th>
												<th>Models</th>
												<th>Complete Vacant</th>
												<th className="w-[4%]">UC</th>
												<th className="w-[4%]">VDL</th>
												<th>Futures</th>
												<th className="w-[10%]">Status</th>
												<th className="w-[10%]">PDF</th>
												<th className="w-[5%]">Total</th>
											</tr>
										</thead>
										<tbody>{<Rows f={f} />}</tbody>
									</table>
								</>
							)}
						/>
						<button
							type="submit"
							className={classNames(`mt-12`, {
								"!bg-transparent border border-darkBlue !text-darkBlue opacity-50": !f.isValid,
							})}>
							Submit
						</button>
					</Form>
				)}
			</Formik>
		)
	}
	return (
		<div className="">
			<div className="group">
				<h2>Add Entries</h2>
				<div className="flex mb-5 items-center w-full">
					<div className="flex items-center w-4/12">
						<p className="text-darkBlue text-sm mr-3">Quarter:</p>
						<Select
							name="quarter"
							className="smallText w-full"
							value={quarter}
							onChange={(k, v) => {
								setQuarter(v)
							}}
							options={helpers.buildQuarters()}
							onBlur={() => {}}
						/>
					</div>
					<div className="flex items-center w-full pl-4">
						<p className="text-darkBlue text-sm mr-3">Subdivision:</p>
						<Select
							name="subdivision"
							className="smallText w-full"
							value={subdivision}
							onChange={(k, v) => {
								setSubdivision(v)
							}}
							options={buildSubdivisions()}
							onBlur={() => {}}
						/>
					</div>
				</div>
				<BuildTable />
			</div>
			<h2 className="mt-12">Current Entries</h2>
			<FullTable data={entries} columns={columns} classNames={"small"} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Entry</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Entry
