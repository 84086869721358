import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import {
	useMainIngestMutation,
	useMainRetrieveQuery,
	useModifyFloorplanMutation,
} from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import NumberField from "components/shared/NumberField"
import Select from "components/Select"
import Matrix from "components/Matrix"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import _ from "lodash"

function Floorplans() {
	const q = useMainRetrieveQuery({ action: "getFloorplans", tags: ["floorplans"] })
	const communities = useMainRetrieveQuery({ action: "getCommunities", tags: ["communities"] })
	const subdivisions = useMainRetrieveQuery({ action: "getSubdivisions", tags: ["subdivisions"] })
	const sections = useMainRetrieveQuery({ action: "getSections", tags: ["sections"] })
	const builders = useMainRetrieveQuery({ action: "getBuilders", tags: ["builders"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useModifyFloorplanMutation()
	const [floorplans, setFloorplans] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	let model = {
		builder: "",
		name: "",
		sqft: "",
		stories: "",
		garage: "",
		beds: "",
		full_baths: "",
		half_baths: "",
		garage_type: "",
		floorplan_pricing: [],
	}
	useEffect(() => {
		if (q.data) {
			setFloorplans(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
			builder: Yup.number().required("Required"),
		})
	}
	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "builder",
			header: "Builder",
			accessorFn: (row) => helpers.translateID("builder", row, builders),
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]
	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					values.lot_types_builders = JSON.stringify(values.lot_types_builders)
					if (props?.new) {
						resp = await create({ ...values, action: "createFloorplan", tags: ["floorplans"] })
					} else {
						resp = await patch(values)
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating floorplan.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "builder")}>
									<ErrorMessage name="builder" component={FormError} className="text-red-500" />
									<label>Builder</label>
									<Select
										name="builder"
										value={f.values.builder}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(builders)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "sqft")}>
									<ErrorMessage name="sqft" component={FormError} />
									<label>Square Feet</label>
									<NumberField
										name="sqft"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.sqft}
										error={f.errors.sqft}
										value={f.values.sqft}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "stories")}>
									<ErrorMessage name="stories" component={FormError} />
									<label>Stories</label>
									<NumberField
										name="stories"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.stories}
										error={f.errors.stories}
										value={f.values.stories}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "garage")}>
									<ErrorMessage name="garage" component={FormError} />
									<label>Garage</label>
									<NumberField
										name="garage"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.garage}
										error={f.errors.garage}
										value={f.values.garage}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "beds")}>
									<ErrorMessage name="beds" component={FormError} />
									<label>Beds</label>
									<NumberField
										name="beds"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.beds}
										error={f.errors.beds}
										value={f.values.beds}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "full_baths")}>
									<ErrorMessage name="full_baths" component={FormError} />
									<label>Full Baths</label>
									<NumberField
										name="full_baths"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.full_baths}
										error={f.errors.full_baths}
										value={f.values.full_baths}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "half_baths")}>
									<ErrorMessage name="half_baths" component={FormError} />
									<label>Half Baths</label>
									<NumberField
										name="half_baths"
										type="text"
										className="form__input"
										onChange={f.setFieldValue}
										onBlur={f.setFieldTouched}
										touched={f.touched.half_baths}
										error={f.errors.half_baths}
										value={f.values.half_baths}
										thousandSeparator={true}
										numeric={true}
									/>
								</li>
								<li className={helpers.formField(f, "garage_type")}>
									<ErrorMessage name="garage_type" component={FormError} />
									<label>Garage Type</label>
									<Select
										name="garage_type"
										value={f.values.garage_type}
										isClearable={true}
										onChange={(k, val) => {
											f.setFieldValue(k, val)
										}}
										options={[
											{
												label: "Front",
												value: 1,
											},
											{
												label: "Rear",
												value: 2,
											},
											{
												label: "Carport",
												value: 3,
											},
											{
												label: "None",
												value: 4,
											},
										]}
										onBlur={f.setFieldTouched}
									/>
								</li>
							</ul>
							<h3>Pricing</h3>
							<p className="text-xs">
								Pricing should be EITHER a community or a subdivision, never both.
							</p>
							<Matrix
								delete={true}
								fields={[
									{
										id: "quarter",
										name: "Quarter",
										type: "select",
										options: helpers.buildQuarters(),
									},
									{
										id: "community",
										name: "Community",
										type: "select",
										options: helpers.buildOpts(communities),
									},
									{
										id: "subdivision",
										name: "Subdivision",
										type: "select",
										options: helpers.buildOpts(subdivisions),
									},
									{
										id: "lot_type",
										name: "Lot Type",
										type: "select",
										options: helpers.buildLotTypes(),
									},
									{
										id: "price",
										name: "Price",
										type: "number",
										thousandSeparator: true,
										numeric: true,
										prefix: "$",
									},
								]}
								data={f.values.floorplan_pricing}
								onChange={(v) => f.setFieldValue("floorplan_pricing", v)}
							/>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add Floorplan</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Floorplans</h2>
			<FullTable data={floorplans} columns={columns} admin={true} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Floorplans</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Floorplans
