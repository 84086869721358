import classnames from "classnames"
import { jwtDecode } from "jwt-decode"
import _ from "lodash"
import moment from "moment"

let helpers = {
	formField: (f, name, opts) => {
		return classnames("formList__item", {
			"is-invalid": f.errors[name] && f.touched[name],
			"is-valid": !f.errors[name] && f.touched[name] && f.values[name],
			full: opts?.full,
		})
	},
	currentUser: () => {
		let user = localStorage.getItem("user")
		user = jwtDecode(user)
		return user
	},
	buildOpts: (d, useFull) => {
		// usefull is for making the labels the full csv value, like Section, Subdivision, Community.  Comes from the server.
		let ret = []
		if (!d?.data) return []
		_.forEach(d.data, (item) => {
			if (useFull) {
				ret.push({ value: item.id, label: item.full })
			} else {
				ret.push({ value: item.id, label: item.name })
			}
		})
		return ret
	},
	buildDbOptions: (opts, name) => {
		if (!opts?.data) return []
		// This is specifically for the options that come from the db, like amenities
		let ret = []
		let f = _.find(opts.data, { name })
		if (!f) return []
		_.forEach(f.value, (item) => {
			ret.push({ value: item.value, label: item.label })
		})
		return ret
	},
	buildPayloadOptions: (opts, name) => {
		if (!opts) return []
		let ret = []
		_.forEach(opts, (v) => {
			ret.push({ value: v.id, label: v.name })
		})
		return ret
	},
	buildSubdivisions: (opts, values) => {
		if (!opts) return []
		let ret = []
		_.forEach(opts, (v) => {
			if (values.communities?.length && !values.communities.includes(v.community)) return
			ret.push({ value: v.id, label: v.name })
		})
		return ret
	},
	buildSchools: (d, school_type) => {
		let ret = []
		if (!d?.data?.data) return []
		_.forEach(d.data.data, (item) => {
			if (item.school_type !== school_type) return
			ret.push({ value: item.id, label: item.name })
		})
		return ret
	},
	translateID: (name, row, d) => {
		if (!d?.data) return ""
		let ret = _.find(d.data, { id: row[name] })
		return ret?.name
	},
	buildQuarters: () => {
		// returns an array of value and label with the value being a unix timestamp and the label is quarter and year, e.g. Q1 2021.  Go back 20 years.
		let ret = []
		let start = moment().subtract(20, "years").startOf("year")
		let end = moment()
		while (start.isBefore(end)) {
			ret.push({ value: start.unix(), label: `Q${start.format("Q YYYY")}` })
			start.add(3, "months")
		}
		ret = _.reverse(ret)
		return ret
	},
	buildLotTypes: () => {
		let ret = []
		for (let i = 20; i <= 150; i++) {
			ret.push({ value: i, label: i })
		}

		return ret
	},
	selectValue: (opts, val, allowNull) => {
		// Allow null to programatically wipe out optsion + selection...used in realtor brokerage typeahead system.
		if (val == null && !allowNull) {
			return
		}
		if (typeof val == "object" && !allowNull) {
			return opts.filter((r) => val.includes(r.value))
		} else {
			return opts.filter((r) => val === r.value)
		}
	},
	stringifyObj: (obj) => {
		let f = {}
		_.forEach(obj, (v, k) => {
			if (typeof v === "object" && v) {
				f[k] = JSON.stringify(v)
			} else {
				f[k] = v
			}
		})
		return f
	},
	observedStatuses: () => {
		return [
			{ value: 1, label: "Vacant Land" },
			{ value: 2, label: "Clearing" },
			{ value: 3, label: "WS&D" },
			{ value: 4, label: "Paving" },
		]
	},
	isFiltersEmpty: (filters) => {
		let empty = true
		_.forEach(filters, (v, k) => {
			if (v && (typeof v === "string" || typeof v === "number" || typeof v === "boolean")) {
				empty = false
				return false
			} else if (typeof v === "object" && v) {
				_.forEach(v, (v2, k2) => {
					if (v2) {
						empty = false
						return false
					}
				})
			}
			if (!empty) return false
		})
		console.log(empty)
		return empty
	},
	latestEntriesInSection(entries) {
		// Pass in a section's entries, get out an array of the latest entries for each builder/lot_type/section combo
		/* const groupedData = entries.reduce((acc, curr) => {
			const { builder, lot_type, section, quarter } = curr
			const key = `${builder}-${lot_type}-${section}`

			if (!acc[key] || curr.quarter > acc[key].quarter) {
				acc[key] = curr
			}

			return acc
		}, {})
		const result = Object.values(groupedData)
		return result */
		let newestQuarter = _.maxBy(entries, "quarter").quarter
		let latestEntries = _.filter(entries, { quarter: newestQuarter })
		return latestEntries
	},
	ttmEntriesInSection(entries) {
		// Pass in a section's entries, get out an array of the ttm entries for each builder/lot_type/section combo
		// figure out the latest quarter
		let latestEntries = this.latestEntriesInSection(entries)
		let latestQuarter = latestEntries.reduce((acc, curr) => {
			return curr.quarter > acc ? curr.quarter : acc
		}, 0)
		let yearAgo = moment.unix(latestQuarter).subtract(1, "year").startOf("quarter").unix()
		let ttmEntries = latestEntries.filter((entry) => entry.quarter >= yearAgo)

		return ttmEntries
	},
	translateArray(arr, payload) {
		_.forEach(arr, (v, k) => {
			_.forEach(v, (val, key) => {
				if (key === "amenities" && Array.isArray(val)) {
					arr[k][key] = val.map((id) => {
						let amenity = _.find(payload.amenities, { value: id })
						return amenity?.label
					})
					arr[k][key] = arr[k][key].join(", ")
				} else if (key === "county") {
					arr[k][key] = _.find(payload.counties, { id: val })?.name
				} else if (key === "developer") {
					arr[k][key] = _.find(payload.developers, { id: val })?.name
				} else if (key === "districts" && Array.isArray(val)) {
					arr[k][key] = val.map((id) => {
						let district = _.find(payload.school_districts, { id })
						return district?.name
					})
					arr[k][key] = arr[k][key].join(", ")
				} else if (key === "schools" && Array.isArray(val)) {
					arr[k][key] = val.map((id) => {
						let school = _.find(payload.schools, { id })
						return school?.name
					})
					arr[k][key] = arr[k][key].join(", ")
				}
			})
		})
		return arr
	},
	translateNamesToIds(arr, data, type) {
		let ret = []
		_.forEach(arr, (v) => {
			_.forEach(data[type], (d) => {
				if (d.name === v) {
					ret.push(d.id)
				} else if (parseInt(d.name) === parseInt(v)) {
					ret.push(d.id)
				}
			})
		})
		return ret
	},
}

export default helpers
