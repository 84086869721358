import React from "react"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import moment from 'moment'

function DateField(props) {
	const handleChange = (v) => {
		if (props.row) {
			props.onChange(`rows[${props.rowIndex}][${props.name}]`, v.unix())
		} else {
			props.onChange(props.name, v.unix())
		}
	}
	let value = moment.unix(props.value)
	if(!props.value) value = null
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DatePicker
					onChange={handleChange}
					className="form__input"
					onBlur={() => {
						props.onBlur(props.name, true)
					}}
					value={value}
				/>
			</LocalizationProvider>
		</>
	)
}

export default DateField
