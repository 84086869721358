import React, { useEffect, useState, useRef } from "react"
import mapboxgl from "mapbox-gl"
mapboxgl.accessToken = "pk.eyJ1IjoiYWFyb25jYmFzIiwiYSI6ImNscjAwOWoybzA3dnIyam44aTJkN3Vwd2sifQ.15gmXjg-0XiY6k0dDV_vBQ"
import "mapbox-gl/dist/mapbox-gl.css"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import classNames from "classnames"

const LatLngPicker = (props) => {
	const mapContainer = useRef(null)
	const [mapType, setMapType] = useState("mapbox://styles/mapbox/streets-v11")
	const [map, setMap] = useState(null)
	const [lng, setLng] = useState(false)
	const [lat, setLat] = useState(false)
	const [zoom, setZoom] = useState(10)
	const [coords, setCoords] = useState({})
	const [markers, setMarkers] = useState([])
	const markersRef = useRef(markers)

	useEffect(() => {
		initMap()
	}, [])

	useEffect(() => {
		wipeMarkers()
	}, [props.triggerWipe])

	useEffect(() => {
		initMap()
	}, [mapType])

	const _setMarkers = (data) => {
		markersRef.current = data
		setMarkers(data)
	}

	const placeMarker = (map, coords) => {
		let marks = [...markersRef.current]
		marks.forEach((marker) => {
			marker.remove()
		})
		let marker = new mapboxgl.Marker().setLngLat(coords).addTo(map)
		_setMarkers([marker])
	}

	const initMap = () => {
		// Check for existing coords
		let existing = false
		let center = [-95.36723795379703, 29.7627587122713]
		if(props?.formik?.values?.lat && props?.formik?.values?.lng) {
			center = [props.formik.values.lng, props.formik.values.lat]
			existing = true
		}
		const map = new mapboxgl.Map({
			container: mapContainer.current,
			style: mapType,
			center,
			pitch: 15,
			zoom,
			minZoom: 10,
			maxZoom: 18,
			attributionControl: false,
		})

		if(existing) {
			placeMarker(map, { lng: props.formik.values.lng, lat: props.formik.values.lat })
		}

		// Add navigation control (the +/- zoom buttons)
		map.addControl(new mapboxgl.NavigationControl(), "bottom-right")
		map.addControl(
			new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl: mapboxgl,
			})
		)
		map.getCanvas().style.cursor = "pointer"

		map.on("click", (e) => {
			if (props.onClick) props.onClick(e.lngLat)
			setCoords(e.lngLat)
			if (props.formik) {
				props.formik.setFieldValue("lat", e.lngLat.lat)
				props.formik.setFieldValue("lng", e.lngLat.lng)
			}
			// React and Mapbox dont' play well, so this accesses the ref to state and modifies
			placeMarker(map, e.lngLat)
		})

		map.on("move", () => {
			setLng(map.getCenter().lng.toFixed(4))
			setLat(map.getCenter().lat.toFixed(4))
			setZoom(map.getZoom().toFixed(2))
		})
		map.on("load", async () => {
			// Existing map, place marker
			if (props.data && props.data.lat && props.data.lng) {
				placeMarker(map, { lng: props.data.lng, lat: props.data.lat })
			}
			setMap(map)
		})
		//map.scrollZoom.disable()
		// Clean up on unmount
		return () => map.remove()
	}

	const wipeMarkers = () => {
		let marks = [...markersRef.current]
		marks.forEach((marker) => {
			marker.remove()
		})
		_setMarkers([])
	}

	return (
		<div>
			<ul className="flex justify-end">
				<li
					className={classNames(`border border-blue-500 bg-white text-blue-500 px-4 py-2 text-sm cursor-pointer`, {
						"!bg-blue-500 !text-white": mapType == "mapbox://styles/mapbox/streets-v11",
					})}
					onClick={() => setMapType("mapbox://styles/mapbox/streets-v11")}>
					Street
				</li>
				<li
					className={classNames(`border border-blue-500 bg-white text-blue-500 px-4 py-2 text-sm cursor-pointer`, {
						"!bg-blue-500 !text-white": mapType == "mapbox://styles/mapbox/satellite-streets-v11",
					})}
					onClick={() => setMapType("mapbox://styles/mapbox/satellite-streets-v11")}>
					Satellite
				</li>
			</ul>
			<div className="LatLngPicker" ref={mapContainer} style={{ height: `${props.height}px` }} />
		</div>
	)
}

export default LatLngPicker
