import React, { useEffect, useState, useRef } from "react"
import FullTable from "components/shared/FullTable"
import { useMainIngestMutation, useMainPatchMutation, useMainRetrieveQuery } from "services/api"
import { Formik, Form, Field, ErrorMessage } from "formik"
import helpers from "helpers"
import FormError from "components/shared/FormError"
import Select from "components/Select"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Modal from "react-responsive-modal"
import _ from "lodash"

function Markets() {
	const q = useMainRetrieveQuery({ action: "getMarkets", tags: ["markets"] })
	const metros = useMainRetrieveQuery({ action: "getMetros", tags: ["metros"] })
	const submarkets = useMainRetrieveQuery({ action: "getSubmarkets", tags: ["submarkets"] })
	const [create, createData] = useMainIngestMutation()
	const [patch, patchData] = useMainPatchMutation()
	const [markets, setMarkets] = useState([])
	const [editData, setEditData] = useState(null)
	const [editModal, setEditModal] = useState(false)
	let model = {
		name: "",
		metro: ""
	}
	useEffect(() => {
		if (q.data) {
			setMarkets(buildRows(q.data))
		}
	}, [q])

	const buildRows = (data) => {
		let ret = []
		_.forEach(data, (item) => {
			ret.push({
				...item,
				edit: (
					<i
						className="fa-solid fa-pen-field cursor-pointer text-primary"
						onClick={() => {
							setEditData(item)
							setEditModal(true)
						}}></i>
				),
			})
		})

		return ret
	}

	const validation = () => {
		return Yup.object().shape({
			name: Yup.string().required("Required"),
		})
	}

	const columns = [
		{
			id: "id",
			header: "ID",
		},
		{
			id: "name",
			header: "Name",
		},
		{
			id: "metro",
			header: "Metro",
			accessorFn: (row) => helpers.translateID('metro', row, metros),
		},
		{
			id: "edit",
			header: "Edit",
			filterable: false,
		},
	]

	const DisplayForm = (props) => {
		let init = _.clone(model)
		if (editData && !props?.new) init = editData
		return (
			<Formik
				initialValues={init}
				validationSchema={validation}
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					let resp
					if (props?.new) {
						resp = await create({ ...values, action: "createMarket", tags: ["markets"] })
					} else {
						resp = await patch({
							...values,
							type: "markets",
							action: "updateResource",
							tags: ["markets"],
						})
					}
					if (resp.data?.status === 200) {
						toast.success(resp.data.message)
						resetForm()
						setEditData(null)
						setEditModal(false)
					} else if (resp.data?.status === 400) {
						toast.error("Error creating user.")
					}
					setSubmitting(false)
				}}>
				{(f) => (
					<>
						<Form>
							{/* console.log(f.values) */}
							<ul className="formList">
								<li className={helpers.formField(f, "name")}>
									<ErrorMessage name="name" component={FormError} />
									<label>Name</label>
									<Field type="text" name="name" />
								</li>
								<li className={helpers.formField(f, "metro")}>
									<ErrorMessage name="metro" component={FormError} className="text-red-500" />
									<label>Metro</label>
									<Select
										name="metro"
										value={f.values.metro}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(metros)}
										onBlur={f.setFieldTouched}
									/>
								</li>
								<li className={helpers.formField(f, "submarkets")}>
									<ErrorMessage name="submarkets" component={FormError} className="text-red-500" />
									<label>Submarkets</label>
									<Select
										name="submarkets"
										value={f.values.submarkets}
										onChange={f.setFieldValue}
										options={helpers.buildOpts(submarkets)}
										onBlur={f.setFieldTouched}
										isMulti
									/>
								</li>
							</ul>
							<button type="submit" disabled={f.isSubmitting} className="mt-12">
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		)
	}

	return (
		<div className="">
			<div className="group">
				<h2>Add Market</h2>
				<DisplayForm new={true} />
			</div>
			<h2 className="mt-12">Current Markets</h2>
			<FullTable data={markets} columns={columns} />
			<Modal open={editModal} onClose={() => setEditModal(false)} center>
				<h2>Edit Market</h2>
				<DisplayForm />
			</Modal>
		</div>
	)
}

export default Markets
