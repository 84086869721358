import React, { useState, useEffect } from "react"
import _ from "lodash"
import NumberField from "components/shared/NumberField"
import Select from "components/Select"
import { Field } from "formik"
import helpers from "helpers"
import classNames from "classnames"
import DateField from "components/shared/DateField"

function Matrix(props) {
	const [data, setData] = useState({})
	const [store, setStore] = useState([])
	const [user, setUser] = useState(helpers.currentUser())

	useEffect(() => {
		buildData()
	}, [])

	useEffect(() => {
		buildFinalResponse()
	}, [store])

	/* componentDidUpdate(prevProps) {
		if (!_.isEqual(props, prevProps)) {
			buildData()
			if (props.initRow && store.length == 0) addRow()
		}
	} */

	const buildData = () => {
		let store = _.cloneDeep(props.data)
		if (!store) store = []
		_.forEach(store, (v, k) => {
			_.forEach(v, (r, key) => {
				if (typeof r == "string" && parseInt(r) && !helpers.hasNonNumericChars(r)) {
					store[k][key] = parseInt(r)
				}
			})
		})
		setStore(store)
	}

	const buildFinalResponse = () => {
		props.onChange(store)
	}

	const handleChange = (v, k, id) => {
		let s = _.cloneDeep(store)
		s[k][id] = v
		setStore(s)
		buildFinalResponse()
	}

	const addRow = () => {
		let s = _.clone(store)
		let model = {}
		_.forEach(props.fields, (v) => {
			model[v.id] = null
		})
		if (props.appendUser) model.user = user.id
		s.push(model)
		setStore(s)
	}

	const buildHeaders = () => {
		let ret = []
		_.forEach(props.fields, (v) => {
			ret.push(
				<th key={v.name} className="text-left text-darkBlue text-sm pb-4">
					{v.name}
				</th>
			)
		})
		return ret
	}

	const buildOptions = (v, val) => {
		if (v.dependent) {
			let ret = []
			_.forEach(props.payload.public[v.lookup], (r) => {
				if (r.property !== val.property) return
				ret.push({
					label: r.name,
					value: r.id,
				})
			})
			return ret
		}
		if (v.options) return v.options
		return []
	}

	const removeRow = (k) => {
		let s = _.cloneDeep(store)
		s.splice(k, 1)
		setStore(s)
		buildFinalResponse()
	}

	const buildRows = () => {
		let ret = []
		_.forEach(store, (val, k) => {
			let s = []
			_.forEach(props.fields, (v) => {
				let field
				if (v.type === "select") {
					field = (
						<Select
							name={`${k}-${v.id}`}
							options={buildOptions(v, val)}
							value={store[k][v.id]}
							onBlur={() => {}}
							onChange={(old, val) => {
								handleChange(val, k, v.id)
							}}
							isMulti={v.isMulti}
						/>
					)
				} else if (v.type === "number") {
					field = (
						<NumberField
							name={store[k][v.id]}
							value={store[k][v.id]}
							type="text"
							className="form__input"
							onChange={(old, val) => handleChange(val, k, v.id)}
							thousandSeparator={true}
							numeric={v.float ? false : true}
							onBlur={() => {}}
							float={v.float || null}
							decimals={v.decimals || null}
							prefix={v.prefix}
						/>
					)
				} else if (v.type === "text") {
					field = (
						<Field
							name={store[k][v.id]}
							value={store[k][v.id]}
							type="text"
							className="form__input"
							onChange={(val) => {
								handleChange(val.target.value, k, v.id)
							}}
						/>
					)
				} else if (v.type === "date") {
					field = (
						<DateField
							id={`${k}-${v.id}`}
							name={store[k][v.id]}
							value={store[k][v.id]}
							onChange={(name, val) => {
								handleChange(val, k, v.id)
							}}
							onBlur={() => {}}
						/>
					)
				} else if (v.type === "textarea") {
					field = (
						<Field
							name={store[k][v.id]}
							value={store[k][v.id]}
							component="textarea"
							className="form__input"
							onChange={(val) => {
								handleChange(val.target.value, k, v.id)
							}}
						/>
					)
				}
				s.push(
					<td style={{ width: v.width }} className="pr-4 pb-3 formList__item formList__item--td" key={`${k}-${v.id}`}>
						{field}
					</td>
				)
			})
			ret.push(
				<tr key={`${k}-${val.id}`}>
					{s}
					{props.delete && (
						<td className="w-8 pl-8" key={`${k}-${val.lot_type}`}>
							<i
								className="fa fa-minus-circle text-red-500 cursor-pointer"
								onClick={() => {
									removeRow(k)
								}}
							/>
						</td>
					)}
				</tr>
			)
		})
		return ret
	}

	return (
		<div className="">
			<p className="flex justify-end" style={{ margin: 0 }}>
				<span
					className="text-green-500 cursor-pointer"
					onClick={() => {
						addRow()
					}}>
					<i className="fa fa-plus-circle" />
				</span>
			</p>
			<table className="w-full">
				<thead>
					<tr>{buildHeaders()}</tr>
				</thead>
				<tbody>{buildRows()}</tbody>
			</table>
		</div>
	)
}

export default Matrix
